var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Numéro employé *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.empForm.id_number,
                              expression: "empForm.id_number"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.empForm.id_number.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.empForm.id_number },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.empForm,
                                "id_number",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Nom *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.empForm.first_name,
                              expression: "empForm.first_name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.empForm.first_name.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.empForm.first_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.empForm,
                                "first_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Prénom *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.empForm.last_name,
                              expression: "empForm.last_name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.empForm.last_name.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.empForm.last_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.empForm,
                                "last_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Email")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.empForm.email,
                              expression: "empForm.email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "deptName",
                            type: "email",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.empForm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.empForm,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Fonction *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.empForm.functionName,
                              expression: "empForm.functionName"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.empForm.functionName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.empForm,
                                "functionName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "formrow-email-input" } },
                            [_vm._v("Type de Structure *")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.userStructuresTypes,
                              placeholder: "Select user structure",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleType",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.empForm.structureType,
                              callback: function($$v) {
                                _vm.$set(_vm.empForm, "structureType", $$v)
                              },
                              expression: "empForm.structureType"
                            }
                          }),
                          _vm.submitted && _vm.$v.empForm.email.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.empForm.email.required
                                  ? _c("span", [
                                      _vm._v(
                                        "La référence du contrat est obligatoire."
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm.empForm.structureType.id == "direction"
                      ? _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.empForm.structureType.name) +
                                      " *"
                                  )
                                ]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.directions,
                                  placeholder: "Select user Direction",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleType",
                                      fn: function(ref) {
                                        var direction = ref.direction
                                        return [_vm._v(_vm._s(direction.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2553682737
                                ),
                                model: {
                                  value: _vm.empForm.direction,
                                  callback: function($$v) {
                                    _vm.$set(_vm.empForm, "direction", $$v)
                                  },
                                  expression: "empForm.direction"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.empForm.structureType.id == "division"
                      ? _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.empForm.structureType.name) +
                                      " *"
                                  )
                                ]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.divisions,
                                  placeholder: "Select user Division",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleType",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [_vm._v(_vm._s(type.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2011112945
                                ),
                                model: {
                                  value: _vm.empForm.division,
                                  callback: function($$v) {
                                    _vm.$set(_vm.empForm, "division", $$v)
                                  },
                                  expression: "empForm.division"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.empForm.structureType.id == "department"
                      ? _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.empForm.structureType.name) +
                                      " *"
                                  )
                                ]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.departments,
                                  placeholder: "Select user Department",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleType",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [_vm._v(_vm._s(type.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2011112945
                                ),
                                model: {
                                  value: _vm.empForm.department,
                                  callback: function($$v) {
                                    _vm.$set(_vm.empForm, "department", $$v)
                                  },
                                  expression: "empForm.department"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.empForm.structureType.id == "service"
                      ? _c("div", { staticClass: "col-md-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.empForm.structureType.name) +
                                      " *"
                                  )
                                ]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.services,
                                  placeholder: "Select user Service",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleType",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [_vm._v(_vm._s(type.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2011112945
                                ),
                                model: {
                                  value: _vm.empForm.service,
                                  callback: function($$v) {
                                    _vm.$set(_vm.empForm, "service", $$v)
                                  },
                                  expression: "empForm.service"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: { switch: "" },
                            model: {
                              value: _vm.empForm.hasUserAccount,
                              callback: function($$v) {
                                _vm.$set(_vm.empForm, "hasUserAccount", $$v)
                              },
                              expression: "empForm.hasUserAccount"
                            }
                          },
                          [_vm._v("Compte utilisateur")]
                        )
                      ],
                      1
                    ),
                    _vm.empForm.hasUserAccount
                      ? _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "deptName" } }, [
                              _vm._v("Nom d'utilisateur")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.empForm.username,
                                  expression: "empForm.username"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "deptName",
                                type: "text",
                                placeholder: "",
                                value: ""
                              },
                              domProps: { value: _vm.empForm.username },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.empForm,
                                    "username",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.empForm.hasUserAccount
                      ? _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "deptName" } }, [
                              _vm._v("Mot de passe")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.empForm.password,
                                  expression: "empForm.password"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "deptName",
                                type: "password",
                                placeholder: "",
                                value: ""
                              },
                              domProps: { value: _vm.empForm.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.empForm,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.empForm.hasUserAccount
                      ? _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [_vm._v("Rôle")]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.roles,
                                  placeholder: "Select user Service",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleRole",
                                      fn: function(ref) {
                                        var role = ref.role
                                        return [_vm._v(_vm._s(role.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2508293693
                                ),
                                model: {
                                  value: _vm.empForm.role,
                                  callback: function($$v) {
                                    _vm.$set(_vm.empForm, "role", $$v)
                                  },
                                  expression: "empForm.role"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.$v.empForm.$invalid,
                        type: "submit"
                      }
                    },
                    [_vm._v(" Enregistrer ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }